<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "LoadingComponent"
})
</script>

<template>
  <div class="position-absolute top-50 start-50 translate-middle text-center">
    <div class="spinner-grow" role="status">
      <span class="visually-hidden">Loading PingPov.net</span>
    </div>
    <h3 class="my-2">Loading PingPov.net</h3>
  </div>
</template>

<style scoped>
.spinner-grow {
  width: 15rem;
  height: 15rem;
}
</style>