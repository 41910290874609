import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "min-vh-100 d-flex flex-column"
}
const _hoisted_3 = { class: "bg-body-tertiary flex-grow-1" }
const _hoisted_4 = { class: "album py-5" }
const _hoisted_5 = { class: "container" }

import FooterComponent from './components/FooterComponent.vue';
import NavbarComponent from './components/NavbarComponent.vue';
import {defineComponent, ref} from "vue";

import event_bus from "@/data/bus";
import LoadingComponent from "@/components/LoadingComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

let loaded = ref(false);
event_bus.on('loaded', (data) => {
  loaded.value = data;
  event_bus.off('loaded');
});

defineComponent({
  name: 'App',
  components: {
    FooterComponent,
    NavbarComponent
  },
  data() {
    return {
      loaded: loaded
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (!_unref(loaded))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(LoadingComponent)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(NavbarComponent),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_router_view)
            ])
          ])
        ]),
        _createVNode(FooterComponent)
      ]))
}
}

})