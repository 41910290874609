<template>
  <div v-if="!loaded">
    <LoadingComponent/>
  </div>
  <div v-else class="min-vh-100 d-flex flex-column">

  <NavbarComponent/>
    <div class="bg-body-tertiary flex-grow-1">

      <div class="album py-5">
        <div class="container">
          <router-view/>
        </div>
      </div>

    </div>
  <FooterComponent/>
  </div>
</template>

<script setup lang="ts">
import FooterComponent from './components/FooterComponent.vue';
import NavbarComponent from './components/NavbarComponent.vue';
import {defineComponent, ref} from "vue";

let loaded = ref(false);
import event_bus from "@/data/bus";
import LoadingComponent from "@/components/LoadingComponent.vue";

event_bus.on('loaded', (data) => {
  loaded.value = data;
  event_bus.off('loaded');
});

defineComponent({
  name: 'App',
  components: {
    FooterComponent,
    NavbarComponent
  },
  data() {
    return {
      loaded: loaded
    }
  }
})
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
