import {createApp} from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./style.css";
import event_bus from "@/data/bus";

import {createPinia, setActivePinia} from "pinia";
const pinia = createPinia();
setActivePinia(pinia);

import App from "./App.vue";
const app = createApp(App);
app.use(pinia);
import router from "./router";
app.use(router);

import { pingpovStore} from "@/data/store";
const store = pingpovStore();
store.fetchTournaments()
    .then(() => {
        event_bus.emit("loaded", true);
    });

app.mount("#app");

