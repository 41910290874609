<template>
  <footer class="text-body-secondary py-3">
    <div class="container">
      <p class="mb-1">Made with ❤️ for PingPov!</p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterComponent",
});
</script>
