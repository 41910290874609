import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-body-secondary py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "container" }, [
      _createElementVNode("p", { class: "mb-1" }, "Made with ❤️ for PingPov!")
    ], -1)
  ])))
}