<template>
    <nav>
    <div ref="navbarHeader" class="text-bg-dark collapse" id="navbarHeader">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 col-md-7 py-4">
<!--            <p class="text-body-secondary">A small community bonded by ping pong :)</p>-->
            <h4><router-link to="/tournaments">Tournaments</router-link></h4>
            <h4><router-link to="/howto">How To</router-link></h4>
            <h4><router-link to="/about">About</router-link></h4>
<!--            <h4><router-link to="/rules">Rules</router-link></h4>-->
          </div>
          <div class="col-sm-4 offset-md-1 py-4">
            
            <h4><router-link to="/contacts">Contacts</router-link></h4>
            <ul class="list-unstyled">
              <li><a href="https://t.me/pingpov" class="text-white">Telegram community</a></li>
              <li><a href="https://challonge.com/it/communities/pingpov/" class="text-white">Challonge community</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar navbar-dark bg-dark shadow-sm">
      <div class="container">
        <router-link to="/" class="navbar-brand d-flex align-items-center">
          <img src="../assets/logo.png" width="40">
          <strong>PingPov</strong>
        </router-link>
        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarHeader"
          aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { Collapse } from "bootstrap";

export default defineComponent({
  name: "NavbarComponent",
  setup() {
    const navbarHeader = ref<HTMLElement | null>(null);
    const router = useRouter();

    const collapseNavbar = () => {
      if (navbarHeader.value) {
        const bsCollapse = new Collapse(navbarHeader.value, {
          toggle: false,
        });
        bsCollapse.hide();
      }
    };

    router.afterEach(() => {
      nextTick(() => {
        collapseNavbar();
      });
    });

    return {
      navbarHeader,
      collapseNavbar,
    };
  },
});
</script>