import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = {
  ref: "navbarHeader",
  class: "text-bg-dark collapse",
  id: "navbarHeader"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-8 col-md-7 py-4" }
const _hoisted_5 = { class: "col-sm-4 offset-md-1 py-4" }
const _hoisted_6 = { class: "navbar navbar-dark bg-dark shadow-sm" }
const _hoisted_7 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h4", null, [
              _createVNode(_component_router_link, { to: "/tournaments" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Tournaments")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("h4", null, [
              _createVNode(_component_router_link, { to: "/howto" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("How To")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("h4", null, [
              _createVNode(_component_router_link, { to: "/about" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("About")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h4", null, [
              _createVNode(_component_router_link, { to: "/contacts" }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Contacts")
                ])),
                _: 1
              })
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("ul", { class: "list-unstyled" }, [
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "https://t.me/pingpov",
                  class: "text-white"
                }, "Telegram community")
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "https://challonge.com/it/communities/pingpov/",
                  class: "text-white"
                }, "Challonge community")
              ])
            ], -1))
          ])
        ])
      ])
    ], 512),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "navbar-brand d-flex align-items-center"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("img", {
              src: _imports_0,
              width: "40"
            }, null, -1),
            _createElementVNode("strong", null, "PingPov", -1)
          ])),
          _: 1
        }),
        _cache[6] || (_cache[6] = _createElementVNode("button", {
          class: "navbar-toggler collapsed",
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarHeader",
          "aria-controls": "navbarHeader",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }, [
          _createElementVNode("span", { class: "navbar-toggler-icon" })
        ], -1))
      ])
    ])
  ]))
}